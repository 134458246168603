@media (max-width: 676px){
  .LazyLoad.is-visible{
    height:auto!important;
  }
  .xch-post-head-left{
    width:40px!important;
    height:40px!important;
    border-radius: 50%;
    margin-right:10px;
    overflow:hidden;
    border-radius: 50%;
  }
  .xch-post-head-left img{
    max-width:40px!important;
    width:40px!important;
  }


  .xch-post-head-name {
    font-size: .9rem!important;
  }
  .xch-post-head-time {
    font-size: .7rem!important;
  }
  .card-body {
    padding: 5px 10px!important;
  }
  .xch-description {
    font-size: .8rem!important;
    margin-bottom: 5px!important;
  }
  .xch-container-inner-sm .col{
    margin-right:0!important;
    margin-left:0!important;
    padding-right:0!important;
    padding-left:0!important;
  }
  .xch-container-inner-sm{
    padding-right:5px!important;
    padding-left:5px!important;
  }
}

@media (max-width: 768px){
  .tf-links{
    font-size:1.5em!important;
  }
}

@media (min-width: 768px){
  .navbar{
    padding:0!important;
  }
}

@media (max-width: 992px){
  .xch-nav .navbar .container{
    margin-left:10px!important;
    margin-right:0!important;
    max-width:none!important;
    width:100%!important;
    padding-left:10px!important;
    padding-right:10px!important;
  }
  *{
    transition:none!important;
  }
}

@media (max-width: 480px){
  .tf-logo {
    height: 50px!important;
    width: auto;
  }
  .tf-logo img {
    height: 50px!important;
    width: auto;
  }
  .xch-main-header button, .xch-w, .xch-main-header .xch-header-b a, .xch-main-header h2{
    font-size:.8rem!important;
  }
}

.xch-container.xch-container-medium{
  max-width:880px!important;
}


@media (min-width: 570px){
  .xch-messages-root-left{
    width:300px!important;
    flex-grow:1!important;
  }
  .xch-messages-root-right{
    flex-grow:2!important;
  }
}

.card-body {
  padding: 5px 10px 10px!important;
}
@media (max-width: 570px){
  .xch-messages-root{
    display:block!important;
  }
  /* .xch-messages-root-left{
    width:300px!important;
  } */
  /* 
  .xch-messages-root-right{
    height:100vh!important;
  }
  */

  .hideMsgBoard, .hideUsers{
    display:none!important;
  }
}

.xch-messages-root-right{
  background-color:#eee!important;
  /* flex-grow:2!important; */
}

.xch-section-mid .xch-main .xch-mid-col{
  padding-right:0!important;
  padding-left:0!important;
}

.navbar-toggler {
  border:none!important
}

.xch-header-avatar{
  width:30px;
  height:30px;
}

.xch-header-msg-a button, .xch-header-a button, .xch-header-b a{
  line-height:30px;
}

.xch-post-card {
  border: 1px solid #ddd!important;
  border-radius: 5px!important;
}

.xch-filters .active, .xch-filters .active:hover{
  background-color: #0d6efd;
  color:#fff!important
}

.xch-sm-txt{
  font-size:.8em;
  font-style: italic;
}

.xch-filters{
  text-align: center;
}

*{
  transition: all .5s ease;
}



.xch-filter-container{
  font-size:18px;
}

.xch-hide-block{
  display:none;
}

.xch-container-pos{
  width:100%!important;
  padding-left:5px!important;
  padding-right:5px!important;
}



.nav-link:focus, .nav-link:hover {
  color: #084298;
}

.tf-logo {
  height: 70px;
  width: auto;
  pointer-events: none;
  align-items: center;
  align-content: center;
  padding:2px 0

}
.tf-logo img{
  height: 70px;
  width: auto;
}

.tf-nav, .tf-links{
  display:flex
}
.tf-nav{
  flex-grow:1;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.tf-links{
  flex-grow:2;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  column-gap: 20px;
  text-transform: uppercase;
  font-size:.8em;
}
.xch-spacer{
  color:#ccc;
}

.xch-section-mid{
  padding-left:0!important;
  padding-right:0!important;
}


.avatar-top{
  width:30px; height:30px;
  border-radius:50%; margin:0 4px;
}

.bg-salmon {
  background-color: salmon;
  color: white;
}

.xch-b{
  justify-content: flex-end;
  display:flex;
  align-items: center;
}

.navbar .xch-b{
  font-size:.8rem!important;
  text-transform: uppercase;
}

.xch-container{
  /* margin-right:15px!important;
  margin-left:15px!important; */
  width:auto!important;
  max-width:none!important;
}

.xch-btn-link{
  text-decoration:none!important;
  padding:0!important;
  font-size:14px!important;
  margin:10px!important;
}

.dropdown-menu{
  border-radius:3px!important;
  background-color:#eee!important;
  color:#fff!important;
  font-size:1em!important;
  border:none!important;
}

.xch-btn-share{margin-left:20px; margin-right:20px;}
.xch-btn-share.btn-fb{margin:0}

.xch-btn-edit{
  text-decoration: none!important;
  color:#555!important;
}

.xch-label{
  font-size:16px!important;
  margin:20px 0 5px 0!important;
  display:block;
  text-align:left!important;
  color:#777!important;
}

.tf-links .tf-link-events a{color: #06a5df!important;}
.tf-links .tf-link-schedule a{color: #ff47ad!important;}
.tf-links .tf-link-news a{color: #07a9a8!important;}
.tf-links .tf-link-xch a{color: #333!important;}


.xch-msg-send{
  background-color:#fff!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tf-links a{
  text-decoration: none;
  border-bottom:2px solid #fff;
}

.tf-links a:hover{
  border-bottom:2px solid #eee;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
  font-weight: 800;
  font-size: 1em;
}

.file-upload-wrapper-profile{
  margin-top:20px;
}

.xch-chat-with{
  font-size:12px; font-style:italic; text-transform:none!important; line-height:30px;
}

.xch-chat-name{
  font-size:20px; font-weight:bold; line-height:30px;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.xch-main-header{
  padding:0 0;
  display:flex;
  height:40px;
  background-color:#000;
  color:#fff;
  margin:0 0 20px 0
}
.xch-btn{
  display:flex;
  padding:5px 0;
  flex-direction: row;
  justify-content: flex-start;
}

.xch-btn-facebook{
  background-color:#3f579d!important;
}
.xch-btn-google{
  background-color:#c73413!important;
}
.xch-btn-icon{
  width:24px;
  height:24px;
}
.xch-form-header{
  font-size:12px; font-weight:700; text-align: center;
}
.xch-btn-left{
  flex-grow: 4;
  max-width:40px;
}

.xch-header-flex{
  display:flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width:800px;
  margin:0 auto;
}



.xch-header-flex a.btn{
  color:#fff!important;
  text-decoration:none;
}

.xch-header-a{
  flex-grow: 1;
  text-align: left;
  width:100px;
}
.xch-header-b{
  flex-grow: 1;
  text-align: right;
  width:100px;
}
.xch-header-c{
  flex-grow: 1;
}

.xch-contact{
  max-width:600px!important;
  margin:0 auto!important;
}

.xch-btn-right{
  flex-grow: 4;
}

.xch-disclaimer{
  font-size:12px;
  margin:30px auto;
  max-width:800px;
  text-align: center;
}

.xch-sign-btn{
  width:300px; margin:0 auto;
  max-width:300px;
  border:0 solid #ccc!important;
  margin:5px 0;
  color:#fff!important;
}
.xch-sign-btn:hover{
  background-color:#000!important;
}


.xch-container-inner-sm{
  max-width:800px;
  margin-left:auto!important;
  margin-right:auto!important;
  padding:10px;
}
.xch-main-header h2{
  font-size:1.2rem;
  line-height:40px;
  color:#fff;
  /* width:100%; */
  text-transform: uppercase;
}
.xch-edit-footer{
  display:flex;
}

.xch-flex-item{
  flex-grow:1;
  display:flex;
}

.xch-item-start{
  align-items: flex-end;
  justify-content: start;

}
.xch-item-end{
  align-items: flex-end;
  justify-content: end;

}


.xch-header-msg-h{
  font-size:1.2rem;
  line-height:40px;
  color:#fff;
  display:flex;
  flex-grow:1;
  text-transform: uppercase;
}


.xch-w{
  flex-grow:1;
  text-align:center;
  font-size: 1.2rem;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
}
.xch-header-msg-a{
  text-align: left;
  width:100px;
}


.xch-main{
  padding:0 0;
}

div.xch-main.row {
  --bs-gutter-x: 0!important;
  --bs-gutter-y: 0;
}

.xch-btn-msg, .xch-btn-share{
  margin:0!important;
  padding:1px 7px!important;
  background-color:#eee!important;
  font-size:.8rem!important
}

.xch-no-posts{text-align: center;}

.xch-btn-msg a, .xch-btn-share a{
  text-decoration:none!important;
  padding:1px 7px!important;
  font-size:.7rem!important
}

.xch-btn-msg:hover, .btn-share:hover, .btn-fb:hover {
  background-color:transparent!important
}

.xch-footer{
  padding:30px 0;
  font-size: .7rem;
}

.xch-share-section{
  text-align:left!important
}

.btn-share, .xch-btn-msg, .btn-fb{
  color:#0d6efd!important;
}


.xch-img-blank{
  max-height:300px;
  width:100%;
  overflow: hidden;
}

.xch-bg-image{
  height:300px;
  cursor: pointer;
  background-position: center ;
  width: 100%;
  overflow: hidden;
}

.xch-bg-image img{
  width:100%!important;
  height:auto!important; 
  /* max-height:300px; */
}

.xch-post-image-container{
  max-height:300px;
  overflow: hidden;
}

.xch-post-image{
  width:100%; height:auto;
}

.xch-post-card{
  margin:10px 0;
}

.xch-label-margin{
  margin:10px 10px 10px 20px!important;
  text-align:center!important;
  font-size:14px!important;
}

.xch-description{
  font-size:14px;
  line-height:1.4em;
  margin-bottom:10px!important;
}

.xch-post-category{
  font-size:11px;
  padding:2px 3px;
  color:#fff;
  text-transform: uppercase;
  border-radius:3px;
  background-color:#06a5df
}

.xch-mesages-myname{
  line-height:50px;
  font-size:14px!important;
  font-weight:600;
}

.xch-post-head, .xch-post-footer{
  display:flex;
  justify-content: center;
  padding:5px;
}
.xch-post-footer-left{
  margin-right:10px;
  overflow:hidden;
  flex-grow:1;
}
.xch-post-footer-right{
  margin-right:10px;
  overflow:hidden;
  flex-grow:0;
}

.xch-post-head-left{
  width:40px;
  height:40px;
  border-radius: 50%;
  margin-right:10px;
  overflow:hidden;
  border-radius: 50%;
}
.xch-post-head-left img{
  max-width:40px;
  width:40px;
}
.xch-img-edit{
  display:flex;
  justify-content: flex-start;
  column-gap: 10px;;
}
.xch-img-icon{width:200px; max-width:200px; max-height:100px; overflow:hidden;}
.xch-img-icon img{width:200px; max-width:200px; height:auto;}
.xch-img-name{flex-grow:4}
.xch-img-size{flex-grow:1;}
/* .xch-img-action{width:100px} */

.xch-label-border{
  border-bottom:2px solid #eee; margin:0 0 10px 0;
}
.xch-post-head-right{
  flex-grow: 1;
}
.xch-post-head-name {
  font-size: 18px;
  font-weight: bold;
}
.xch-post-head-time{
  font-size:12px;
}
.outerbar{
  font-size: 12px; margin:5px 0 0 0; 
}

.xch-nav, .xch-page-title{
  padding-left:0!important;
  padding-right:0!important;
}




@media (min-width: 992px){
  .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
  }
  .xch-messages-root{
    max-width:800px!important;
    margin:0 auto!important;
  }
}
@media (max-width: 992px){
/* HANDLE NAV */
.xch-messages-root{
  max-width:800px!important;
  margin:0 auto!important;
}


#basic-navbar-nav.show {
  background-color: #fff;
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 1000;
  left: 0;
  }

  #basic-navbar-nav.show .xch-nav-a, #basic-navbar-nav.show .xch-nav-c {
    flex-grow: 1;
  }

  #basic-navbar-nav.show .xch-b{
    height: 100vh;
  }

  #basic-navbar-nav.show .xch-b a{
    font-size:1.4rem
  }

  #basic-navbar-nav.show .xch-b #basic-nav-dropdown,
  #basic-navbar-nav.show .xch-b .dropdown-menu
  {
    display:block!important;
  }

  .xch-sm-menu{
    font-size:1.2rem!important;
  }
}



